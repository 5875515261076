body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
  background-color: #003b71;
  height: 50px;
  width: 100vw;
}

.sidebar {
  width: 300px;
  height: 100vh;
  background-color: #003b71;
}

.add-user {
  border: 1px solid #eaedf3;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.04);
  border-radius: 4px;
  padding: 20px 40px;
  position: absolute;
  top: 30%;
  left: 45%;
  margin: 0 auto;
}

.select {
  width: 400px !important;
}

.button-group {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 20px auto;
}

label {
  opacity: 0.6;
}

.cancel, .add {
  background-color: #fff;
  color: #003b71;
  border: 1px solid #003b71;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.add {
  background-color: #003b71;
  color: #fff;
}
